<template>
    <vx-card title="Good Receive Reversal Approval">
        <div class="flex vx-input-group">
            <vs-tabs :value="activeTab">
                <vs-tab label="Waiting">
                    <tab-waiting></tab-waiting>
                </vs-tab>                
                <vs-tab label="Approved">
                    <tab-approved></tab-approved>
                </vs-tab>
                <vs-tab label="Rejected">
                    <tab-rejected></tab-rejected>
                </vs-tab>
                <vs-tab label="Executed">
                    <tab-executed></tab-executed>
                </vs-tab>
            </vs-tabs>
        </div>
    </vx-card>
</template>
<script>
import TabApproved from "./TabApproved.vue";
import TabRejected from "./TabRejected.vue";
import TabWaiting from "./TabWaiting.vue";
import TabExecuted from "./TabExecuted.vue";
export default {
    components: {
        TabWaiting,
        TabApproved,
        TabRejected,
        TabExecuted,
    },
    data() {
        return {
            activeTab: sessionStorage.getItem('activeTabPO') ? sessionStorage.getItem('activeTabPO') : "0",
        }
    },
    methods: { },
    mounted(){
        console.log("index ===== ", sessionStorage.getItem('activeTabPO'))
    }
}
</script>
